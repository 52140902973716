import accountApi from 'shared/utils/api/account'
import { Animate, AnimateGroup } from 'react-simple-animate'
import clone from 'lodash/clone'
import { getMarkets } from 'shared/utils/api/markets'
import { Input, Select, Checkbox } from 'shared/components/form'
import { phone } from 'shared/utils/sanitize'
import { Meta, Title } from 'shared/components/seo'
import { useEffect, useState } from 'react'
import useForm from 'shared/utils/hooks/useForm'
import * as yup from 'yup'

export default () => {
  // Set the props for our simple animation component
  const animateProps = {
    startStyle: { opacity: 0 },
    endStyle: { opacity: 1 },
  }
  const [error, setError] = useState(false)
  const [markets, setMarkets] = useState([])
  const [qualified, setQualified] = useState(false)

  // Get values from the form hook
  const {
    handleChange,
    handleSubmit,
    submitting,
    validationErrors,
    values,
  } = useForm({
    // By default, the qualification object fields are null
    defaultValues: {
      brokerageName: null,
      email: null,
      firstName: null,
      hasAgent: null,
      lastName: null,
      marketOfInterest: null,
      phone: null,
      preApproved: null,
      type: null,
      agreeToTerms: 'false',
      subscribedToSms: 'false',
    },
    onSubmit,
    validationSchema: yup.object().shape({
      // Brokerage name is a string required when type is Agent
      brokerageName: yup
        .string()
        .ensure()
        .when('type', (type, brokerageName) =>
          type === 'Agent'
            ? brokerageName.required('Brokerage Name is required')
            : brokerageName.nullable(),
        ),
      // Email is a required email string
      email: yup
        .string()
        .ensure()
        .email()
        .required('Email is required'),
      // First name is a required string
      firstName: yup
        .string()
        .ensure()
        .required('First name is required'),
      // Has Agent is a boolean required when type is Buyer
      hasAgent: yup
        .boolean()
        .when('type', (type, hasAgent) =>
          type === 'Buyer'
            ? hasAgent.oneOf([true, false], 'Has Agent is required')
            : hasAgent.nullable(),
        ),
      // Last name is a required string
      lastName: yup
        .string()
        .ensure()
        .required('Last name is required'),
      // Market of interest is a required string
      marketOfInterest: yup
        .string()
        .ensure()
        .required('Market of Interest is required'),
      // Phone is a required string that must be 10 digits without the dashes
      phone: yup
        .string()
        .ensure()
        .required('Phone is required')
        .min(10, 'Phone must be 10 digits')
        .test('phone', 'Phone must be a valid US number', value => {
          // const num = value.startsWith('1') ? value.substring(1) : value
          const num =
            value !== null && value.split('')[0] === '1'
              ? value.substring(1)
              : value
          return num.length === 10
        }),
      // Preapproved is a string required when type is Buyer
      preApproved: yup
        .string()
        .ensure()
        .when('type', {
          is: 'Buyer',
          then: preApproved => preApproved.required('This is required'),
        }),
      // Type is a required string
      type: yup
        .string()
        .ensure()
        .required('Type is required'),
      agreeToTerms: yup.boolean().oneOf([true], 'Required'),
    }),
  })

  // On submit, we'll validate, format the phone number, and hit the pecanReSignup endpoint. On success we'll set qualified to true, on error we will show the error component
  async function onSubmit() {
    try {
      let data = clone(values)
      data.phone = phone(data.phone)
      data.hasAgent = data.hasAgent === 'false' ? false : data.hasAgent

      //CloneDeep is converting the boolean to string true/false. This converts it back to boolean
      data.subscribedToSms = data.subscribedToSms === 'true'

      await accountApi.pecanReSignup(data)

      setQualified(true)
    } catch (e) {
      setError(true)
    }
  }

  // Handle the phone change to allow for masking and preventing alphabet and symbol inputs
  const handlePhoneChange = event => {
    event.preventDefault()

    event.target.value =
      event.target.value === null
        ? ''
        : event.target.value.replace(/[^0-9]+/g, '').substring(0, 11)
    handleChange(event)
  }

  // Setting the value to whether the checkbox is checked or not
  const handleCheckboxChange = event => {
    event.target.value = event.target.checked

    handleChange(event)
  }

  // Get markets on mount
  useEffect(() => {
    getMarkets().then(response => {
      const miami = {
        name: 'Miami',
        id:
          process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'master'
            ? '3f37fc30-1ab0-6ff6-0024-16e7ed7adb00'
            : process.env.API_BASE_URL_ENV === 'qa'
            ? '3f37fc30-1ab0-6ff6-0024-16e7ed7adb00'
            : '3f37fc30-1ab0-6ff6-0024-16e7ed7adb00',
      }
      setMarkets([...response, miami])
    })
  }, [])

  // The error component
  const Error = () => {
    return (
      <div className="p-2 w-full">
        <div className="p-2 w-full">
          <img
            className="mx-auto"
            src="/static/img/icon-error@2x.png"
            width={100}
          />
        </div>
        <div className="font-serif p-2 text-center text-3xl">Oops...</div>
        <div className="pb-2 px-2 text-center text-gray-500 w-full">
          Something went wrong. Give us a call at <strong>512-580-9339</strong>{' '}
          during business hours and we'll get you access.
        </div>
        <div className="px-2 py-8 w-full">
          <a
            className={`bg-black hover:bg-gray-900 block p-2 rounded text-center text-white w-full`}
            href="tel://5125809339"
          >
            Contact Us
          </a>
        </div>
      </div>
    )
  }

  // The success component
  const Qualified = ({ phone }) => {
    return (
      <div className="p-2 simple-transition-128 w-full">
        <div className="p-2 w-full">
          <img
            className="mx-auto"
            src="/static/img/icon-success@2x.png"
            width={100}
          />
        </div>
        <div className="font-serif p-2 text-center text-3xl">
          And you're in!
        </div>
        <div className="pb-6 px-2 text-center text-gray-500 w-full">
          You can tour anytime between 8am-8pm, 7 days a week.
        </div>
        <div className="p-2">
          <div className="bg-gray-200 px-16 py-12 rounded text-center">
            <img
              className="mb-4 mx-auto"
              src="/static/img/icon-text@2x.png"
              width={40}
            />
            We've sent a text to <strong>{phone}</strong> with instructions to
            tour.
          </div>
        </div>
      </div>
    )
  }

  // The submitting state component
  const Submitting = () => {
    return (
      <div className="flex p-2 simple-transition-128 w-full">
        <div className="flex m-auto p-2">
          <AnimateGroup play>
            <Animate {...animateProps} sequenceIndex={0}>
              <div className="bg-black h-4 mr-4 rounded-full w-4" />
            </Animate>
            <Animate {...animateProps} sequenceIndex={1}>
              <div className="bg-black h-4 mr-4 rounded-full w-4" />
            </Animate>
            <Animate {...animateProps} sequenceIndex={2}>
              <div className="bg-black h-4 rounded-full w-4" />
            </Animate>
          </AnimateGroup>
        </div>
      </div>
    )
  }

  return (
    <>
      <Title title="Unlock Home Tour Access" />
      <Meta
        content="Unlock access to tour our homes anytime between 8am-8pm, 7 days a week."
        name="description"
      />
      <div className="m-auto px-2 py-8 max-w-xl">
        {error && (
          <Animate play {...animateProps}>
            <Error />
          </Animate>
        )}
        {submitting && (
          <Animate play {...animateProps}>
            <Submitting />
          </Animate>
        )}
        {!error && qualified && !submitting && (
          <Animate play {...animateProps} durationSeconds={0.5}>
            <Qualified phone={values.phone} />
          </Animate>
        )}
        {!error && !qualified && !submitting && (
          <>
            <div className="p-2 w-full">
              <img
                className="mx-auto"
                src="/static/img/icon-tours@2x.png"
                width={100}
              />
            </div>
            <h1 className="font-serif normal m-2 text-3xl text-center w-full">
              Unlock Home Tour Access
            </h1>
            <div className="pb-2 px-2 text-center text-gray-500 w-full">
              Fill out the form below to unlock access to tour anytime between
              8am-8pm, 7 days a week.
            </div>
            <form action="/" className="flex flex-wrap" onSubmit={handleSubmit}>
              <div className="px-2 py-3 w-full">
                {markets.length > 0 && (
                  <Select
                    error={validationErrors.marketOfInterest}
                    label="Market"
                    name="marketOfInterest"
                    onChange={handleChange}
                    options={[
                      { title: '', value: null },
                      ...markets.map(market => ({
                        title: market.name,
                        value: market.id,
                      })),
                    ]}
                  />
                )}
              </div>
              <div className="px-2 py-3 w-full">
                <Select
                  error={validationErrors.type}
                  label="I am a..."
                  name="type"
                  onChange={handleChange}
                  options={[
                    { title: '', value: null },
                    { title: 'Real Estate Agent', value: 'Agent' },
                    { title: 'Home Buyer', value: 'Buyer' },
                  ]}
                />
              </div>
              <div
                className={`px-2 py-3 w-full ${
                  values.type !== 'Agent' ? 'hidden' : ''
                }`}
              >
                <Input
                  error={validationErrors.brokerageName}
                  label="Brokerage Name"
                  name="brokerageName"
                  onChange={handleChange}
                  placeholder="Name of Your Brokerage"
                />
              </div>
              <div
                className={`flex flex-wrap w-full ${
                  values.type !== 'Buyer' ? 'hidden' : ''
                }`}
              >
                <div className="px-2 py-3 w-full lg:w-1/2">
                  <Select
                    error={validationErrors.preApproved}
                    label="Have You Been Pre-Approved?"
                    name="preApproved"
                    onChange={handleChange}
                    options={[
                      { title: '', value: null },
                      { title: 'Yes', value: 'Yes' },
                      { title: 'No', value: 'No' },
                    ]}
                  />
                </div>
                <div className="px-2 py-3 w-full lg:w-1/2">
                  <Select
                    error={validationErrors.hasAgent}
                    label="Working with an Agent?"
                    name="hasAgent"
                    onChange={handleChange}
                    options={[
                      { title: '', value: null },
                      { title: 'Yes', value: true },
                      { title: 'No', value: false },
                    ]}
                  />
                </div>
              </div>
              <div className="px-2 py-3 w-full lg:w-1/2">
                <Input
                  error={validationErrors.firstName}
                  label="First Name"
                  name="firstName"
                  onChange={handleChange}
                  placeholder="First Name"
                />
              </div>
              <div className="px-2 py-3 w-full lg:w-1/2">
                <Input
                  error={validationErrors.lastName}
                  label="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  placeholder="Last Name"
                />
              </div>
              <div className="px-2 py-3 w-full lg:w-1/2">
                <Input
                  error={validationErrors.email}
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  placeholder="Email Address"
                  type="email"
                />
              </div>
              <div className="px-2 py-3 w-full lg:w-1/2">
                <Input
                  error={validationErrors.phone}
                  helpText="Phone number must be able to receive text messages."
                  label="Cell Phone Number"
                  name="phone"
                  onChange={handlePhoneChange}
                  placeholder="Phone"
                  type="tel"
                />
              </div>
              <div className="px-2 py-3 w-full">
                <Checkbox
                  name="agreeToTerms"
                  onChange={handleCheckboxChange}
                  label={
                    <div className="inline">
                      I have read and accept the{' '}
                      <a
                        className="font-medium hover:text-brown-100"
                        href="/terms"
                      >
                        Terms & Conditions
                      </a>{' '}
                      and{' '}
                      <a
                        className="font-medium hover:text-brown-100"
                        href="/privacy"
                      >
                        Privacy Policy
                      </a>{' '}
                      and agree to receive calls/texts at the above number from
                      Pecan and its agents; standard data rates apply; may be
                      autodialed/pre-recorded; reply STOP to cancel text
                      messages.
                    </div>
                  }
                  error={validationErrors.agreeToTerms}
                />
              </div>
              <div className="px-2 py-3 w-full">
                <Checkbox
                  name="subscribedToSms"
                  onChange={handleCheckboxChange}
                  defaultChecked={false}
                  label="I also agree you may send promotional texts to the number above."
                />
              </div>
              <div className="px-2 py-3 text-center w-full">
                <button
                  className="bg-black hover:bg-brown-100 block mb-2 p-2 rounded simple-transition-128 text-white hover:text-black w-full"
                  type="submit"
                >
                  Submit
                </button>
                <span className="italic text-gray-500 text-xs">
                  By clicking submit, you agree to Pecan RE's Terms & Conditions
                  and Privacy Policy
                </span>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  )
}
